import './OfferView.css';
import publicOffer_pdf from './publicOffer_pdf.pdf';

function OfferView() {
    return (
        <div className="offer-view">
            <div className="offer-view--wrap">
                <iframe className="offer-view__public-offer" src={publicOffer_pdf}></iframe>
            </div>
        </div>
    );
}

export default OfferView;